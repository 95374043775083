import threeDoorRoof from '@glass/assets/images/window-selection/3DoorHatch/3-Door-Hatch-Roof.webp'
import threeDoor from '@glass/assets/images/window-selection/3DoorHatch/3-Door-Hatch.webp'
import fiveDoorRoof from '@glass/assets/images/window-selection/5DoorHatch/5-Door-Hatch-Roof.webp'
import fiveDoor from '@glass/assets/images/window-selection/5DoorHatch/5-Door-Hatch.webp'
import coupeRoof from '@glass/assets/images/window-selection/Coupe/Coupe-Roof.webp'
import coupe from '@glass/assets/images/window-selection/Coupe/Coupe.webp'
import estateRoof from '@glass/assets/images/window-selection/Estate/Estate-Roof.webp'
import estate from '@glass/assets/images/window-selection/Estate/Estate.webp'
import sedanRoof from '@glass/assets/images/window-selection/Sedan/Sedan-Roof.webp'
import sedan from '@glass/assets/images/window-selection/Sedan/Sedan.webp'
import vanBarnRoof from '@glass/assets/images/window-selection/VAN_Barn/VAN-Barn-door-roof.webp'
import vanBarn from '@glass/assets/images/window-selection/VAN_Barn/VAN-Barn-door.webp'
import vanTailgaterRoof from '@glass/assets/images/window-selection/VAN_Tailgater/VAN-Tailgater-Roof.webp'
import vanTailgater from '@glass/assets/images/window-selection/VAN_Tailgater/VAN-Tailgater.webp'
import { CarType } from '@glass/enums'

export const CAR_IMAGES: { [key: string]: string } = {
  [CarType.DEFAULT]: threeDoor,
  [CarType.THREE_DOOR]: threeDoor,
  [CarType.FIVE_DOOR]: fiveDoor,
  [CarType.COUPE]: coupe,
  [CarType.ESTATE]: estate,
  [CarType.SEDAN]: sedan,
  [CarType.BARN]: vanBarn,
  [CarType.TAILGATER]: vanTailgater,
}

export const CAR_IMAGES_GLASS_ROOF: { [key: string]: string } = {
  [CarType.DEFAULT]: threeDoorRoof,
  [CarType.THREE_DOOR]: threeDoorRoof,
  [CarType.FIVE_DOOR]: fiveDoorRoof,
  [CarType.COUPE]: coupeRoof,
  [CarType.ESTATE]: estateRoof,
  [CarType.SEDAN]: sedanRoof,
  [CarType.BARN]: vanBarnRoof,
  [CarType.TAILGATER]: vanTailgaterRoof,
}
