import React from 'react'
import { Loader } from '../Loader'

interface LazyComponentProps {
  children: React.ReactElement
}

const LazyComponent = ({ children }: LazyComponentProps) => {
  return <React.Suspense fallback={<Loader loading />}>{children}</React.Suspense>
}

export default LazyComponent
