import front from '@glass/assets/images/window-selection/3DoorHatch/front.webp'
import l_1 from '@glass/assets/images/window-selection/3DoorHatch/l_1.webp'
import l_2 from '@glass/assets/images/window-selection/3DoorHatch/l_2.webp'
import l_3 from '@glass/assets/images/window-selection/3DoorHatch/l_3.webp'
import l_3_t from '@glass/assets/images/window-selection/3DoorHatch/l_3_t.webp'
import r_1 from '@glass/assets/images/window-selection/3DoorHatch/r_1.webp'
import r_2 from '@glass/assets/images/window-selection/3DoorHatch/r_2.webp'
import r_3 from '@glass/assets/images/window-selection/3DoorHatch/r_3.webp'
import r_3_t from '@glass/assets/images/window-selection/3DoorHatch/r_3_t.webp'
import rear from '@glass/assets/images/window-selection/3DoorHatch/rear.webp'
import rear_t from '@glass/assets/images/window-selection/3DoorHatch/rear_t.webp'
import roof from '@glass/assets/images/window-selection/3DoorHatch/roof.webp'
import { WinLoc } from '@glass/enums'
import { WindowSelection } from '@glass/models'

export const THREE_DOOR_WINDOWS: WindowSelection[] = [
  { name: 'Front windscreen', window: WinLoc.FRONT, broken: false, source: front, hasTinted: false },
  { name: 'Left front quarter', window: WinLoc.L1, broken: false, source: l_1, hasTinted: false },
  { name: 'Left front door/drop', window: WinLoc.L2, broken: false, source: l_2, hasTinted: false },
  {
    name: 'Left rear body quarter',
    window: WinLoc.L3,
    broken: false,
    source: l_3,
    hasTinted: true,
    tintedSource: l_3_t,
  },
  { name: 'Right front quarter', window: WinLoc.R1, broken: false, source: r_1, hasTinted: false },
  { name: 'Right front door/drop', window: WinLoc.R2, broken: false, source: r_2, hasTinted: false },
  {
    name: 'Right rear body quarter',
    window: WinLoc.R3,
    broken: false,
    source: r_3,
    hasTinted: true,
    tintedSource: r_3_t,
  },
  { name: 'Backlight', window: WinLoc.REAR, broken: false, source: rear, hasTinted: true, tintedSource: rear_t },
]

export const THREE_DOOR_WINDOWS_ROOF: WindowSelection[] = [
  ...THREE_DOOR_WINDOWS,
  { name: 'Glass roof', window: WinLoc.ROOF, broken: false, source: roof, hasTinted: false },
]
