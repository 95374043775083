import { CarType } from '@glass/enums'
import { WindowSelection } from '@glass/models'
import { COUPE_WINDOWS, COUPE_WINDOWS_ROOF } from './coupe-winddows.constant'
import { ESTATE_WINDOWS, ESTATE_WINDOWS_ROOF } from './estate-winddows.constant'
import { FIVE_DOOR_WINDOWS, FIVE_DOOR_WINDOWS_ROOF } from './fivedoor-winddows.constant'
import { SEDAN_WINDOWS, SEDAN_WINDOWS_ROOF } from './sedan-winddows.constant'
import { THREE_DOOR_WINDOWS, THREE_DOOR_WINDOWS_ROOF } from './threedoor-winddows.constant'
import { VANS_WINDOWS, VANS_WINDOWS_ROOF } from './vans-winddows.constant'

export const WINDOWS: { [key: string]: WindowSelection[] } = {
  [CarType.DEFAULT]: THREE_DOOR_WINDOWS,
  [CarType.THREE_DOOR]: THREE_DOOR_WINDOWS,
  [CarType.FIVE_DOOR]: FIVE_DOOR_WINDOWS,
  [CarType.COUPE]: COUPE_WINDOWS,
  [CarType.ESTATE]: ESTATE_WINDOWS,
  [CarType.SEDAN]: SEDAN_WINDOWS,
  [CarType.BARN]: VANS_WINDOWS,
  [CarType.TAILGATER]: VANS_WINDOWS,
}

export const WINDOWS_ROOF: { [key: string]: WindowSelection[] } = {
  [CarType.DEFAULT]: THREE_DOOR_WINDOWS_ROOF,
  [CarType.THREE_DOOR]: THREE_DOOR_WINDOWS_ROOF,
  [CarType.FIVE_DOOR]: FIVE_DOOR_WINDOWS_ROOF,
  [CarType.COUPE]: COUPE_WINDOWS_ROOF,
  [CarType.ESTATE]: ESTATE_WINDOWS_ROOF,
  [CarType.SEDAN]: SEDAN_WINDOWS_ROOF,
  [CarType.BARN]: VANS_WINDOWS_ROOF,
  [CarType.TAILGATER]: VANS_WINDOWS_ROOF,
}
