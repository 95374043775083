import { WinLoc } from '@glass/enums'

export const FIVE_DOOR_COORDS: { [key: string]: string } = {
  [WinLoc.FRONT]: '210,709,166,494,172,468,306,408,410,396,489,398,625,437,675,469,683,501,643,712,426,693',
  [WinLoc.REAR]: '196,1334,214,1257,268,1275,428,1284,585,1273,635,1257,650,1333,580,1389,425,1409,271,1391',
  [WinLoc.R1]: '654,679,747,627,720,478,687,509',
  [WinLoc.R2]: '627,922,743,858,747,636,655,686,635,768',
  [WinLoc.R3]: '624,952,623,1129,755,1133,747,905',
  [WinLoc.R4]: '626,1134,626,1182,707,1261,750,1222,753,1141',
  [WinLoc.L1]: '195,682,160,512,122,499,103,627',
  [WinLoc.L2]: '105,641,199,691,219,787,223,919,95,869',
  [WinLoc.L3]: '227,956,225,1126,93,1130,96,903',
  [WinLoc.L4]: '222,1132,219,1184,159,1253,99,1242,98,1138',
}

export const FIVE_DOOR_COORDS_ROOF: { [key: string]: string } = {
  ...FIVE_DOOR_COORDS,
  [WinLoc.ROOF]: '316,813,526,814,530,1116,320,1116',
}
