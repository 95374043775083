import front from '@glass/assets/images/window-selection/Sedan/front.webp'
import l_1 from '@glass/assets/images/window-selection/Sedan/l_1.webp'
import l_2 from '@glass/assets/images/window-selection/Sedan/l_2.webp'
import l_3 from '@glass/assets/images/window-selection/Sedan/l_3.webp'
import l_3_t from '@glass/assets/images/window-selection/Sedan/l_3_t.webp'
import l_4 from '@glass/assets/images/window-selection/Sedan/l_4.webp'
import l_4_t from '@glass/assets/images/window-selection/Sedan/l_4_t.webp'
import r_1 from '@glass/assets/images/window-selection/Sedan/r_1.webp'
import r_2 from '@glass/assets/images/window-selection/Sedan/r_2.webp'
import r_3 from '@glass/assets/images/window-selection/Sedan/r_3.webp'
import r_3_t from '@glass/assets/images/window-selection/Sedan/r_3_t.webp'
import r_4 from '@glass/assets/images/window-selection/Sedan/r_4.webp'
import r_4_t from '@glass/assets/images/window-selection/Sedan/r_4_t.webp'
import rear from '@glass/assets/images/window-selection/Sedan/rear.webp'
import rear_t from '@glass/assets/images/window-selection/Sedan/rear_t.webp'
import roof from '@glass/assets/images/window-selection/Sedan/roof.webp'
import { WinLoc } from '@glass/enums'
import { WindowSelection } from '@glass/models'

export const SEDAN_WINDOWS: WindowSelection[] = [
  { name: 'Front windscreen', window: WinLoc.FRONT, broken: false, source: front, hasTinted: false },
  { name: 'Left front quarter', window: WinLoc.L1, broken: false, source: l_1, hasTinted: false },
  { name: 'Left front door/drop', window: WinLoc.L2, broken: false, source: l_2, hasTinted: false },
  { name: 'Left rear door/drop', window: WinLoc.L3, broken: false, source: l_3, hasTinted: true, tintedSource: l_3_t },
  { name: 'Left rear door vent', window: WinLoc.L4, broken: false, source: l_4, hasTinted: true, tintedSource: l_4_t },
  { name: 'Right front quarter', window: WinLoc.R1, broken: false, source: r_1, hasTinted: false },
  { name: 'Right front door/drop', window: WinLoc.R2, broken: false, source: r_2, hasTinted: false },
  { name: 'Right rear door/drop', window: WinLoc.R3, broken: false, source: r_3, hasTinted: true, tintedSource: r_3_t },
  { name: 'Right rear door vent', window: WinLoc.R4, broken: false, source: r_4, hasTinted: true, tintedSource: r_4_t },
  { name: 'Backlight', window: WinLoc.REAR, broken: false, source: rear, hasTinted: true, tintedSource: rear_t },
]

export const SEDAN_WINDOWS_ROOF: WindowSelection[] = [
  ...SEDAN_WINDOWS,
  { name: 'Glass roof', window: WinLoc.ROOF, broken: false, source: roof, hasTinted: false },
]
