import { CarType } from '@glass/enums'
import { COUPE_COORDS, COUPE_COORDS_ROOF } from './coupe-coords.constant'
import { ESTATE_COORDS, ESTATE_COORDS_ROOF } from './estate-coords.constant'
import { FIVE_DOOR_COORDS, FIVE_DOOR_COORDS_ROOF } from './five-door-coords.constant'
import { SEDAN_COORDS, SEDAN_COORDS_ROOF } from './sedan-coords.constant'
import { THREE_DOOR_COORDS, THREE_DOOR_COORDS_ROOF } from './three-door-coords.constant'
import { VANS_COORDS, VANS_COORDS_ROOF } from './vans-coords.constant'

export const COORDS: { [key: string]: { [key: string]: string } } = {
  [CarType.DEFAULT]: THREE_DOOR_COORDS,
  [CarType.THREE_DOOR]: THREE_DOOR_COORDS,
  [CarType.FIVE_DOOR]: FIVE_DOOR_COORDS,
  [CarType.COUPE]: COUPE_COORDS,
  [CarType.ESTATE]: ESTATE_COORDS,
  [CarType.SEDAN]: SEDAN_COORDS,
  [CarType.BARN]: VANS_COORDS,
  [CarType.TAILGATER]: VANS_COORDS,
}

export const COORDS_ROOF: { [key: string]: { [key: string]: string } } = {
  [CarType.DEFAULT]: THREE_DOOR_COORDS_ROOF,
  [CarType.THREE_DOOR]: THREE_DOOR_COORDS_ROOF,
  [CarType.FIVE_DOOR]: FIVE_DOOR_COORDS_ROOF,
  [CarType.COUPE]: COUPE_COORDS_ROOF,
  [CarType.ESTATE]: ESTATE_COORDS_ROOF,
  [CarType.SEDAN]: SEDAN_COORDS_ROOF,
  [CarType.BARN]: VANS_COORDS_ROOF,
  [CarType.TAILGATER]: VANS_COORDS_ROOF,
}
