import threeDoorTintedRoof from '@glass/assets/images/window-selection/3DoorHatch/3-Door-Hatch-Tint-Roof.webp'
import threeDoorTinted from '@glass/assets/images/window-selection/3DoorHatch/3-Door-Hatch-Tint.webp'
import fiveDoorTintedRoof from '@glass/assets/images/window-selection/5DoorHatch/5-Door-Hatch-Tint-Roof.webp'
import fiveDoorTinted from '@glass/assets/images/window-selection/5DoorHatch/5-Door-Hatch-Tint.webp'
import coupeTintedRoof from '@glass/assets/images/window-selection/Coupe/Coupe-Tint-Roof.webp'
import coupeTinted from '@glass/assets/images/window-selection/Coupe/Coupe-Tint.webp'
import estateTintedRoof from '@glass/assets/images/window-selection/Estate/Estate-Tint-Roof.webp'
import estateTinted from '@glass/assets/images/window-selection/Estate/Estate-Tint.webp'
import sedanTintedRoof from '@glass/assets/images/window-selection/Sedan/Sedan-Tint-Roof.webp'
import sedanTinted from '@glass/assets/images/window-selection/Sedan/Sedan-Tint.webp'
import vanBarnTintedRoof from '@glass/assets/images/window-selection/VAN_Barn/VAN-Barn-door-tint-roof.webp'
import vanBarnTinted from '@glass/assets/images/window-selection/VAN_Barn/VAN-Barn-door-tint.webp'
import vanTailgaterTintedRoof from '@glass/assets/images/window-selection/VAN_Tailgater/BLAC-VAN-TAGLIATELE-Tint-Roof.webp'
import vanTailgaterTinted from '@glass/assets/images/window-selection/VAN_Tailgater/BLAC-VAN-TAGLIATELE-Tint.webp'
import { CarType } from '@glass/enums'

export const CAR_TINTED_IMAGES: { [key: string]: string } = {
  [CarType.DEFAULT]: threeDoorTinted,
  [CarType.THREE_DOOR]: threeDoorTinted,
  [CarType.FIVE_DOOR]: fiveDoorTinted,
  [CarType.COUPE]: coupeTinted,
  [CarType.ESTATE]: estateTinted,
  [CarType.SEDAN]: sedanTinted,
  [CarType.BARN]: vanBarnTinted,
  [CarType.TAILGATER]: vanTailgaterTinted,
}

export const CAR_TINTED_IMAGES_ROOF: { [key: string]: string } = {
  [CarType.DEFAULT]: threeDoorTintedRoof,
  [CarType.THREE_DOOR]: threeDoorTintedRoof,
  [CarType.FIVE_DOOR]: fiveDoorTintedRoof,
  [CarType.COUPE]: coupeTintedRoof,
  [CarType.ESTATE]: estateTintedRoof,
  [CarType.SEDAN]: sedanTintedRoof,
  [CarType.BARN]: vanBarnTintedRoof,
  [CarType.TAILGATER]: vanTailgaterTintedRoof,
}
