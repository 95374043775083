export enum CarType {
  THREE_DOOR = '3door',
  FIVE_DOOR = '5door',
  COUPE = 'coupe',
  ESTATE = 'estate',
  SEDAN = 'sedan',
  BARN = 'barn',
  TAILGATER = 'tailgater',
  DEFAULT = 'default', //default is similar three door for fix case image resize map
}
