import './App.css'
import React, { useEffect, useState } from 'react'
import { createTheme } from '@mui/material/styles'
import { ThemeProvider } from '@mui/system'
import { usePromiseTracker } from 'react-promise-tracker'
import { Routes, Route, useLocation } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
const LazyFooter = React.lazy(() => import('@glass/components/Footer'))
const LazyHeader = React.lazy(() => import('@glass/components/Header'))
import { Loader } from '@glass/components/Loader'
const LazyPaymentAssist = React.lazy(() => import('@glass/components/PaymentAssist'))
const LazyContact = React.lazy(() => import('@glass/pages/Contact'))
const LazyCustomer = React.lazy(() => import('@glass/pages/Customer'))
const LazyHome = React.lazy(() => import('@glass/pages/Home'))
// const LazyPaid = React.lazy(() => import('@glass/pages/Paid'))
const LazyPricing = React.lazy(() => import('@glass/pages/Pricing'))
const LazyQuotePage = React.lazy(() => import('@glass/pages/Quote'))
const LazyServices = React.lazy(() => import('@glass/pages/Services'))
import { setRequestedURL } from '@glass/utils/session/session.util'
import 'react-toastify/dist/ReactToastify.css'
import LazyComponent from './components/LazyComponent'
const LazyCustomerFormBE = React.lazy(() => import('@glass/pages/CustomerFormBE'))
const LazyPaRedirection = React.lazy(() => import('@glass/pages/PaRedirection/PaRedirection'))
// import { Payl8rPage } from './pages/Payl8r'
const LazyQuotePagePreviewBE = React.lazy(() => import('@glass/pages/Quote/QuotePreviewBE'))

export const LoadingIndicator: React.FC = () => {
  const { promiseInProgress } = usePromiseTracker()
  return <Loader loading={promiseInProgress} />
}

const theme = createTheme({
  spacing: 4,
  breakpoints: {
    values: {
      xs: 0,
      sm: 576,
      md: 768,
      lg: 992,
      xl: 1200,
    },
  },
  typography: {
    fontFamily: [
      'Nunito Sans',
      '-apple-system',
      'BlinkMacSystemFont',
      'Segoe UI',
      'Roboto',
      'Oxygen',
      'Ubuntu',
      'Cantarell',
      'Fira Sans',
      'Droid Sans',
      'Helvetica Neue',
      'sans-serif',
    ].join(','),
  },
  palette: {
    primary: {
      main: '#9557e8', // Define your custom color
    },
  },
  components: {
    MuiTypography: {
      styleOverrides: {
        root: {
          color: 'var(--Gray-800, #14151F)',
        },
        h3: {
          fontWeight: '700',
        },
        h4: {
          fontWeight: '700',
        },
        h5: {
          fontWeight: '700',
        },
        h6: {
          fontWeight: '700',
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          color: 'var(--Light-Blue---Primary-500, #225fc2)',
          '&:hover': {
            color: 'var(--Light-Blue---Primary-500, #225fc2)',
          },
          textDecoration: 'none',
          cursor: 'pointer',
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          color: '#9557e8',
          '&.Mui-checked': {
            color: '#9557e8',
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          fontWeight: 700,
        },
        contained: {
          backgroundColor: '#9557e8',
          borderRadius: 6,
          color: '#ffffff',
          '&:hover': {
            backgroundColor: '#7239b3',
          },
          '&.Mui-disabled': {
            backgroundColor: '#f0e6ff',
            color: '#aaa',
          },
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          display: 'list-item',
          paddingLeft: 0,
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '.MuiOutlinedInput-input': {
            paddingBlock: '12px 12px',
            width: '100%',
            lineHeight: '24px',
            boxSizing: 'border-box',
            height: 'unset',
          },
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          padding: '4px 8px 4px 8px',
          borderRadius: '4px',
          background: '#3B3B3B',
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          backgroundColor: '#D8D8DA',
        },
      },
    },
    // MuiTextField: {
    //   styleOverrides: {
    //     root: {
    //       '& .MuiInput-underline:after': {
    //         borderBottomColor: '#9557e8', // Focus color for underline variant
    //       },
    //       '& .MuiOutlinedInput-root': {
    //         '&.Mui-focused fieldset': {
    //           borderColor: '#9557e8', // Focus color for outlined variant
    //         },
    //       },
    //     },
    //   },
    // },
  },
})

export const App: React.FC = () => {
  const location = useLocation()
  const [showMenu, setShowMenu] = useState<boolean>(true)
  // hide header and footer in BE
  const [showHeader, setShowHeader] = useState<boolean>(true)

  useEffect(() => {
    setShowHeader(
      !location.pathname.startsWith('/be_new_inquiry') && !location.pathname.startsWith('/quote/be/preview'),
    )
    setShowMenu(!location.pathname.startsWith('/quote') && !location.pathname.startsWith('/customer'))
    if (location.pathname === '/' && location.search) {
      setRequestedURL(window.location.href)
    }

    // if (location.pathname.startsWith('/payl8r-finance')) {
    //   window.scrollTo({ top: 0, behavior: 'smooth' })
    // }
  }, [location])

  return (
    <>
      <div className='main-content'>
        <div className='empty-test'>-</div>
        <LoadingIndicator />
        <ThemeProvider theme={theme}>
          {showHeader && (
            <LazyComponent>
              <LazyHeader showMenu={showMenu} />
            </LazyComponent>
          )}
          <Routes>
            <Route
              path='/'
              element={
                <LazyComponent>
                  <LazyHome />
                </LazyComponent>
              }
            />
            <Route
              path='/services'
              element={
                <LazyComponent>
                  <LazyServices />
                </LazyComponent>
              }
            />
            <Route
              path='/pricing'
              element={
                <LazyComponent>
                  <LazyPricing />
                </LazyComponent>
              }
            />
            <Route
              path='/contact'
              element={
                <LazyComponent>
                  <LazyContact />
                </LazyComponent>
              }
            />
            <Route
              path='/customer'
              element={
                <LazyComponent>
                  <LazyCustomer />
                </LazyComponent>
              }
            />
            <Route
              path='/customer/:licenseNum'
              element={
                <LazyComponent>
                  <LazyCustomer />
                </LazyComponent>
              }
            />
            <Route
              path='/customer/edit/:licenseNum/:quoteId'
              element={
                <LazyComponent>
                  <LazyCustomer editMode={true} />
                </LazyComponent>
              }
            />
            <Route
              path='/payment/assist/:status'
              element={
                <LazyComponent>
                  <LazyPaymentAssist />
                </LazyComponent>
              }
            />
            <Route
              path='/payment/:status'
              element={
                <LazyComponent>
                  <LazyPaymentAssist />
                </LazyComponent>
              }
            />
            {/* <Route
              path='/paid'
              element={
                <LazyComponent>
                  <LazyPaid />
                </LazyComponent>
              }
            /> */}
            <Route
              path='/quote/:id'
              element={
                <LazyComponent>
                  <LazyQuotePage />
                </LazyComponent>
              }
            />
            <Route
              path='/quote/in/:id'
              element={
                <LazyComponent>
                  <LazyQuotePage />
                </LazyComponent>
              }
            />
            <Route
              path='/quote/be/:id'
              element={
                <LazyComponent>
                  <LazyQuotePage quoteCount={false} />
                </LazyComponent>
              }
            />
            {/* <Route path='/payl8r-finance' element={<Payl8rPage />} /> */}
            <Route
              path='/payment/assist/success/:id'
              element={
                <LazyComponent>
                  <LazyPaRedirection isSuccess={true} />
                </LazyComponent>
              }
            />
            <Route
              path='/payment/assist/failed/:id'
              element={
                <LazyComponent>
                  <LazyPaRedirection />
                </LazyComponent>
              }
            />
            <Route
              path='/be_new_inquiry'
              element={
                <LazyComponent>
                  <LazyCustomerFormBE />
                </LazyComponent>
              }
            />
            <Route
              path='/quote/be/preview/:id'
              element={
                <LazyComponent>
                  <LazyQuotePagePreviewBE quoteCount={false} />
                </LazyComponent>
              }
            />
          </Routes>
          {showMenu && showHeader && (
            <LazyComponent>
              <LazyFooter />
            </LazyComponent>
          )}
          <ToastContainer position='bottom-right' />
        </ThemeProvider>
      </div>
    </>
  )
}

export default App
